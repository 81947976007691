<template>
  <div class="chip">
    <button
      v-for="chip in chips"
      :key="chip.id"
      class="chip__button"
      :class="{
        'chip__button--filled': filled.includes(chip.id)
      }"
      type="button"
      @click="onClick(chip.id)"
    >
      {{ chip.label }}
    </button>
  </div>
</template>
<script>
export default {
  name: 'BChip',
  props: {
    chips: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filled: []
    };
  },
  mounted() {
    this.filled = this.chips
      .filter((chip) => chip.selected)
      .map((chip) => chip.id);
  },
  methods: {
    onClick(chipId) {
      if (!this.multiple) {
        this.filled = [chipId];
        this.$emit('click', this.filled);
        return;
      }
      this.filled = this.filled.includes(chipId)
        ? this.filled.filter((id) => id !== chipId)
        : [...this.filled, chipId];
      this.$emit('click', this.filled);
    }
  }
};
</script>
<style lang="scss" scoped>
.chip {
  &__button {
    line-height: 16px;
    padding: 6px 16px;
    min-height: 30px;
    font-size: 14px;
    background-color: transparent;
    border: 1px solid var(--v-textPrimary-lighten1);
    border-radius: 500px;
    color: var(--v-textPrimary-lighten1);
    transition: all 0.14s ease-in-out;
    margin-right: 12px;
    margin-bottom: 12px;
    &:hover:not(.chip__button--filled) {
      color: var(--v-textPrimary-lighten2);
      border-color: var(--v-borderPrimary-li--v-textPrimary-lighten2);
      background-color: var(--v-backgroundPrimary-lighten4);
    }
    &--filled {
      background: var(--v-backgroundPrimary-base);
      color: var(--v-textPrimary-lighten5);
    }
  }
}
</style>
